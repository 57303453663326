<template>
  <div class="table_wrapper">
    <v-btn
      color="primary"
      class="mb-5"
      v-on:click="
        (dialog = true),
          (dataObject = {
            icon: '',
            id: null,
            name: '',
            name_visible: false,
          })
      "
      >New</v-btn
    >
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:[`item.c5`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              v-on:click="(dialog = true), (dataObject = item.item)"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              v-on:click="deleteItem(item.item.id)"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dataObject ? "Edit" : "New" }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="name"
                  v-model="dataObject.name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  label="Is Visible?"
                  v-model="dataObject.name_visible"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-img
                  :src="dataObject.icon"
                  max-width="50"
                  max-height="50"
                ></v-img>
                <v-file-input
                  label="Default Photo"
                  v-on:change="uploadImage"
                  :loading="isImageLoad"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" v-on:click="dialog = false">Close</v-btn>
          <v-btn color="primary" :loading="loadingSave" v-on:click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Confirm from "../Components/Confirm.vue";

import moment from "moment";
export default {
  name: "SupplierTypes",
  data() {
    return {
      dialog: false,
      loadingSave: false,
      selected: [],
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Name", value: "c2", sortable: false },
        { text: "Created at", value: "c3", sortable: false },
        { text: "Updated at", value: "c4", sortable: false },
        { text: "action", value: "c5", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      isImageLoad: false,
      dataObject: {
        icon: "",
        id: null,
        name: "",
        name_visible: false,
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    deleteItem: function(id) {
      this.$refs.confirm
        .open("Delete", "Are you sure?", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            axios
              .delete(
                process.env.VUE_APP_API_URL +
                  "supplier-types/" +
                  id +
                  "?api_token=" +
                  this.token
              )
              .then((res) => {
                this.getDataFromApi(this.activeTab);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    save: function() {
      this.loadingSave = true;
      if (this.dataObject.id) {
        axios
          .put(
            process.env.VUE_APP_API_URL +
              "supplier-types/" +
              this.dataObject.id +
              "?api_token=" +
              this.token,
            this.dataObject
          )
          .then((res) => {
            this.dialog = false;
            this.getDataFromApi(this.activeTab);
            this.loadingSave = false;
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "supplier-types?api_token=" +
              this.token,
            this.dataObject
          )
          .then((res) => {
            this.dialog = false;
            this.getDataFromApi(this.activeTab);
            this.loadingSave = false;
          })
          .catch((err) => console.log(err));
      }
    },
    uploadImage: function(file) {
      if (!file) {
        return false;
      }
      let formDataClass = new FormData();
      formDataClass.append("file", file);

      try {
        this.isImageLoad = true;
        axios
          .post(process.env.VUE_APP_API_URL + "upload", formDataClass, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.dataObject.icon = res.data.file_paths[0];
            this.isImageLoad = false;
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
        this.isImageLoad = false;
      }
    },
    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c1: item.id,
            c2: item.name,
            c3: this.parseDateTime(item.created_at),
            c4: this.parseDateTime(item.updated_at),
            id: item.id,
            item: item,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          resolve({
            itemsR,
            total,
          });
        });
      });
    },
    async getDesserts() {
      const fields = [];
      const sort = [];

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
            "supplier-types/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "",
          }
        );
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
  components: {
    confirm: Confirm,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
